import { ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Radio, Tabs, theme, Typography } from "@ogury/design-system";

import { ReferenceService } from "services";
import { Layout } from "components";
import AdUnitsService from "services/AdUnitsService/AdUnitsService";
import { useAdUnitContext } from "context/AdUnitContext";
import { adUnitUrlValues } from "services/ReferenceService/ReferenceService";
import { CreativeDataProps } from "types";
import { SiteData, UnitData } from "./Types";
import { MobileContent, MobileFormTab } from "./components";
import BannerList from "./components/BannerList";
import DesktopFormTab from "./components/DesktopFormTab";
import styles from "./DemoPageOCS.module.scss";
import BannerDetail from "./components/BannerDetail";

enum DEMO_PAGE_MODE {
  MOBILE = "MOBILE",
  DESKTOP = "IAB_BANNERS",
}

type Props = {
  readonly siteData: SiteData;
  readonly creativeData?: CreativeDataProps;
  readonly forcePreview: boolean;
};

function DemoPageOCS({ siteData, creativeData, forcePreview }: Props): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, setAdUnitContext] = useAdUnitContext();
  const adUnitUrlParam = useMemo(() => {
    if (creativeData) {
      return searchParams.get(ReferenceService.urlParameters.AU) || creativeData.adUnit;
    }
    if (siteData) {
      return adUnitUrlValues.BANNER.key;
    }
    return "";
  }, [searchParams, creativeData, siteData]);

  const { t } = useTranslation();
  const desktopAdUnits = useMemo(() => siteData?.units?.filter((unit) => unit.technicalId.startsWith("banner")) || [], [siteData]);
  const mobileAdUnits = useMemo(
    () => siteData?.units?.filter((unit) => unit.externalId && !unit.technicalId.startsWith("banner")).toSorted((a, b) => a.technicalId.localeCompare(b.technicalId)) || [],
    [siteData]
  );
  const hasMobile = useMemo(() => mobileAdUnits.length > 0, [mobileAdUnits]);
  const hasDesktop = useMemo(() => desktopAdUnits.length > 0, [desktopAdUnits]);

  const redirectToFirstMobile = () => {
    const firstMobileAdUnit = mobileAdUnits[0]?.externalId;
    const firstMobileAdUnitUrlKey = mobileAdUnits[0]?.urlKey;

    setSearchParams({
      [ReferenceService.urlParameters.CID]: firstMobileAdUnit,
      [ReferenceService.urlParameters.AU]: firstMobileAdUnitUrlKey,
    });
  };

  const redirectToDesktop = () => {
    setSearchParams({
      [ReferenceService.urlParameters.SID]: siteData.id,
      [ReferenceService.urlParameters.AU]: adUnitUrlValues.BANNER.key,
    });
  };

  const mode = useMemo(() => {
    if (adUnitUrlParam === adUnitUrlValues.BANNER.key && hasDesktop) {
      return DEMO_PAGE_MODE.DESKTOP;
    }
    if (hasMobile) {
      return DEMO_PAGE_MODE.MOBILE;
    }
    return undefined;
  }, [adUnitUrlParam, hasDesktop, hasMobile]);

  useEffect(() => {
    // correct URL if it's not valid
    if (adUnitUrlParam === adUnitUrlValues.BANNER.key && !hasDesktop && hasMobile) {
      redirectToFirstMobile();
    }

    if (adUnitUrlParam !== adUnitUrlValues.BANNER.key && !hasMobile && hasDesktop) {
      redirectToDesktop();
    }
  }, [searchParams]);

  const handleModeChange = (value: DEMO_PAGE_MODE) => {
    if (value === DEMO_PAGE_MODE.MOBILE) {
      redirectToFirstMobile();
    } else {
      redirectToDesktop();
    }
  };

  const templateId = useMemo(() => {
    if (siteData?.units.length > 0) {
      return siteData?.units[0].templateId;
    }
    return null;
  }, [siteData]);

  useEffect(() => {
    if (!creativeData) {
      return;
    }

    const units: UnitData[] = siteData?.units?.filter((unit) => unit.externalId === creativeData.creativeId);
    if (units?.length > 0) {
      const unit = units[0];
      setAdUnitContext({
        technicalId: unit.technicalId,
        inArticleType: AdUnitsService.computeInArticleType(unit.technicalId),
      });
    }
  }, [creativeData]);

  return (
    <Layout>
      <Layout.Sider>
        {templateId && (
          <div className={styles.templateDisplay}>
            <Typography.P2Strong color={theme.colors.content.secondary}>{t("DemoShowcase.template")}</Typography.P2Strong>
            <Typography.P2Regular style={{ textTransform: "capitalize" }}>{templateId}</Typography.P2Regular>
          </div>
        )}
        <Radio.Group onChange={handleModeChange} value={mode} className={styles.tabSelector}>
          <Radio.Button disabled={!hasMobile} value={DEMO_PAGE_MODE.MOBILE}>
            {t("DemoShowcase.Mobile")}
          </Radio.Button>
          <Radio.Button disabled={!hasDesktop} value={DEMO_PAGE_MODE.DESKTOP}>
            {t("DemoShowcase.IabBanners")}
          </Radio.Button>
        </Radio.Group>

        <Tabs
          tabBarStyle={{ display: "none" }}
          activeKey={mode}
          items={[
            {
              key: DEMO_PAGE_MODE.MOBILE,
              children: creativeData && <MobileFormTab creativeData={creativeData} adUnits={mobileAdUnits} />,
            },
            {
              key: DEMO_PAGE_MODE.DESKTOP,
              children: <DesktopFormTab creativeData={creativeData} siteData={siteData} adUnits={desktopAdUnits} />,
            },
          ]}
        />
      </Layout.Sider>
      <Layout.Content>
        <Tabs
          destroyInactiveTabPane
          tabBarStyle={{ display: "none" }}
          activeKey={mode}
          className={styles.content}
          items={[
            {
              key: DEMO_PAGE_MODE.MOBILE,
              children: creativeData && <MobileContent creativeData={creativeData} forcePreview={forcePreview} />,
            },
            {
              key: DEMO_PAGE_MODE.DESKTOP,
              children: creativeData ? (
                <BannerDetail creativeData={creativeData} forcePreview={forcePreview} />
              ) : (
                <BannerList forcePreview={forcePreview} adUnits={desktopAdUnits} />
              ),
            },
          ]}
        />
      </Layout.Content>
    </Layout>
  );
}

export default DemoPageOCS;
