import { ReactElement } from "react";

import { CreativeDataProps } from "types/Types";
import { Layout } from "components";
import { LeftPanel } from "./components";
import { MobileContent } from "../DemoPageOCS/components";
import style from "./DemoPage.module.scss";

type Props = { forcePreview: boolean; creativeData: CreativeDataProps | undefined };
function DemoPage({ forcePreview, creativeData }: Props): ReactElement {
  return (
    <Layout>
      {creativeData !== undefined && (
        <>
          <Layout.Sider>{!forcePreview && <LeftPanel creativeData={creativeData} />}</Layout.Sider>
          <Layout.Content>
            <div className={style.content}>
              <MobileContent creativeData={creativeData} forcePreview={forcePreview} />
            </div>
          </Layout.Content>
        </>
      )}
    </Layout>
  );
}
export default DemoPage;
