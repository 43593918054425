import { CreativeDataProps } from "types";
import { Card, Skeleton, Spin } from "@ogury/design-system";
import { Creative } from "components";
import { deviceType } from "services/ReferenceService/ReferenceService";
import { useMemo } from "react";
import styles from "./BannerDetail.module.scss";

type Props = Readonly<{
  creativeData?: CreativeDataProps;
  forcePreview: boolean;
  // adUnits: Array<UnitData>;
}>;

function ParagraphPlaceholder({ rows = 6 }: { readonly rows?: number }) {
  return (
    <div className={styles.paragraphPlaceholder}>
      <Skeleton.Image />
      <Skeleton title={false} paragraph={{ rows, width: "100%" }} />
    </div>
  );
}
function BannerDetail({ forcePreview, creativeData }: Props) {
  const creativeRatio = useMemo(() => {
    if (!creativeData) {
      return 0;
    }

    if (creativeData.dimension?.width && creativeData.dimension?.height) {
      return creativeData.dimension.width / creativeData.dimension.height;
    }
    return 0;
  }, [creativeData]);

  const creativeLayout = useMemo(() => {
    if (creativeRatio >= 3 && creativeData?.dimension && creativeData.dimension.height > 50 && creativeData.dimension.height < 100) {
      return "topBanner";
    }
    if (creativeRatio >= 3 && creativeData?.dimension && creativeData.dimension.height <= 50) {
      return "headerBanner";
    }
    if (creativeRatio >= 3 && creativeData?.dimension && creativeData.dimension.height <= 100) {
      return "mainBanner";
    }
    if (creativeRatio <= 0.5) {
      return "tower";
    }
    return "default";
  }, [creativeRatio, creativeData?.dimension]);

  const renderLayout = (layout: string, creative: CreativeDataProps) => {
    switch (layout) {
      case "tower": {
        const paragraphRows = creative.dimension?.height ? Math.ceil((creative.dimension.height - 140) / 40 / 4) : 6;
        return (
          <div className={styles.contentWrapper} style={{ gridTemplateColumns: "auto 1fr 1fr" }}>
            <ParagraphPlaceholder rows={paragraphRows} />
            <div className={styles.creativePlaceholder} style={{ gridColumnStart: 1, gridColumnEnd: 1, gridRowStart: 1, gridRowEnd: 3 }}>
              <div style={{ height: `${creative.dimension?.height}px`, width: `${creative.dimension?.width}px` }}>
                <Creative creativeData={creative} device={deviceType.DESKTOP} forcePreview={forcePreview} />
              </div>
            </div>

            <ParagraphPlaceholder rows={paragraphRows} />
            <ParagraphPlaceholder rows={paragraphRows} />
            <ParagraphPlaceholder rows={paragraphRows} />
          </div>
        );
      }
      case "default": {
        const paragraphRows = creative.dimension?.height ? Math.floor((creative.dimension.height - 140) / 40) : 6;
        return (
          <div className={styles.contentWrapper} style={{ gridTemplateColumns: "1fr auto 1fr" }}>
            <ParagraphPlaceholder rows={paragraphRows} />
            <div className={styles.creativePlaceholder} style={{ gridColumnStart: 2, gridColumnEnd: 2 }}>
              <div style={{ height: `${creative.dimension?.height}px`, width: `${creative.dimension?.width}px` }}>
                <Creative creativeData={creative} device={deviceType.DESKTOP} forcePreview={forcePreview} />
              </div>
            </div>

            <ParagraphPlaceholder rows={paragraphRows} />
            <ParagraphPlaceholder rows={paragraphRows} />
            <ParagraphPlaceholder rows={paragraphRows} />
            <ParagraphPlaceholder rows={paragraphRows} />
          </div>
        );
      }
      default:
        return (
          <div className={styles.contentWrapper}>
            <ParagraphPlaceholder />
            <ParagraphPlaceholder />
            <ParagraphPlaceholder />
            <ParagraphPlaceholder />
            <ParagraphPlaceholder />
            <ParagraphPlaceholder />
          </div>
        );
    }
  };
  if (!creativeData) {
    return (
      <Card size="small" className={styles.mainWrapper}>
        <Spin spinning />
      </Card>
    );
  }

  return (
    <Card size="small" className={styles.mainWrapper}>
      <div className={styles.headerWrapper}>
        {creativeLayout === "mainBanner" ? (
          <div className={styles.creativePlaceholder}>
            <div style={{ height: `${creativeData.dimension?.height}px`, width: `${creativeData.dimension?.width}px` }}>
              <Creative creativeData={creativeData} device={deviceType.DESKTOP} forcePreview={forcePreview} />
            </div>
          </div>
        ) : (
          <Skeleton.Button shape="round" size="large" />
        )}

        <Skeleton.Input />
        <Skeleton.Input />

        {creativeLayout === "headerBanner" ? (
          <div className={styles.creativePlaceholder}>
            <div style={{ height: `${creativeData.dimension?.height}px`, width: `${creativeData.dimension?.width}px` }}>
              <Creative creativeData={creativeData} device={deviceType.DESKTOP} forcePreview={forcePreview} />
            </div>
          </div>
        ) : (
          <Skeleton.Input />
        )}
      </div>
      <div className={styles.bannerWrapper}>
        {creativeLayout === "topBanner" ? (
          <div className={styles.creativePlaceholder}>
            <div style={{ height: `${creativeData.dimension?.height}px`, width: `${creativeData.dimension?.width}px` }}>
              <Creative creativeData={creativeData} device={deviceType.DESKTOP} forcePreview={forcePreview} />
            </div>
          </div>
        ) : (
          <Skeleton.Button block />
        )}
      </div>

      {renderLayout(creativeLayout, creativeData)}
    </Card>
  );
}

export default BannerDetail;
