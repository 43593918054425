import { ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { ReferenceService, URLService } from "services";
import { Row, Typography } from "components";
import { Form, Select } from "@ogury/design-system";

import { useAdUnitContext } from "context/AdUnitContext";
import AdUnitsService from "services/AdUnitsService/AdUnitsService";
import { adUnitUrlValues } from "services/ReferenceService/ReferenceService";
import { CreativeDataProps } from "types";
import style from "./LeftPanel.module.scss";

type LeftPanelProps = {
  creativeData: CreativeDataProps;
};

function LeftPanel({ creativeData }: LeftPanelProps): ReactElement {
  const { t } = useTranslation();
  const allParams = window.location.href.split("?");
  const adUnit = useMemo(() => ReferenceService.dictionary.adUnitDictionary().find((cty) => cty.key === creativeData.adUnit), []);
  const isBanner = useMemo(() => creativeData.adUnit === ReferenceService.adUnitUrlValues.BANNER.key, [creativeData]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, setAdUnitContext] = useAdUnitContext();

  const adUnitOptions = useMemo(
    () =>
      AdUnitsService.adUnits
        .filter((unit) => AdUnitsService.getAdUnitsAvailableForVideoFormat().includes(unit.technicalId))
        .map((unit) => ({ label: unit.name, value: unit.technicalId })),
    [AdUnitsService.adUnits]
  );

  useEffect(() => {
    const adUnitKey = creativeData.adUnit;
    const currentUnit = Object.values(adUnitUrlValues).find((unitUrl) => unitUrl.key === adUnitKey);
    let technicalId = currentUnit?.uniqueId || adUnitUrlValues.INTERSTITIAL.uniqueId;
    if (technicalId === "in_article") {
      technicalId = `${creativeData.inArticleType ? `in_article-${creativeData.inArticleType}` : "in_article-reveal"}`;
    }
    form.setFieldValue("adUnit", technicalId);
    setAdUnitContext({
      technicalId,
      inArticleType: AdUnitsService.computeInArticleType(technicalId),
    });
  }, [adUnitOptions]);

  function renderAdUnitLabel() {
    if (isBanner) {
      return `${adUnit?.label} ${creativeData?.dimension?.width}x${creativeData?.dimension?.height}`;
    }
    return adUnit?.label;
  }

  const handleAdUnitChange = (technicalId: string) => {
    if (technicalId) {
      const adUnitUrlParam = Object.values(adUnitUrlValues).find((unitUrl) => technicalId.startsWith(unitUrl.uniqueId))?.key;
      // refresh page for proper creative reload
      navigate({
        pathname: window.location.pathname,
        search: createSearchParams({
          ...Object.fromEntries(searchParams),
          [ReferenceService.urlParameters.CID]: creativeData.creativeId,
          [ReferenceService.urlParameters.AU]: adUnitUrlParam || Object.fromEntries(searchParams)[ReferenceService.urlParameters.AU],
        }).toString(),
      });
      setAdUnitContext({
        technicalId,
        inArticleType: AdUnitsService.computeInArticleType(technicalId),
      });
    }
  };

  function renderAdUnitSection() {
    if (creativeData.mediaType === "video") {
      return (
        <Form form={form}>
          <Form.Item disabled name="adUnit" label={t("MobileForm.adUnitLabel")} required>
            <Select width="100%" onChange={handleAdUnitChange} options={adUnitOptions} />
          </Form.Item>
        </Form>
      );
    }
    return (
      <>
        <Row top={16}>
          <Typography variant="body1">{t("DemoShowcase.adFormat", "Ad Format")}</Typography>
        </Row>
        <Row>
          <Typography variant="body1Bold">{renderAdUnitLabel()}</Typography>
        </Row>
      </>
    );
  }

  return (
    <div className={style.container}>
      <div>
        {creativeData.advertiserName && (
          <>
            <Row top={40}>
              <Typography variant="body1">{t("DemoShowcase.advertiser", "Advertiser")}</Typography>
            </Row>
            <Row className={style.breakNewLine}>
              <Typography variant="body1Bold">{creativeData.advertiserName}</Typography>
            </Row>
          </>
        )}

        {renderAdUnitSection()}

        {!isBanner && (
          <>
            <Row top={40} padding={3} className={style.QRcodeBorder}>
              <QRCode value={`${URLService.getPublicURL()}?${allParams[1]}`} bgColor="#FFFFFF" fgColor="#000000" size={140} />
            </Row>
            <Row>
              <Typography variant="body2">{t("DemoShowcase.seeMoreOnPhone", "See this demo on your phone")}</Typography>
            </Row>
          </>
        )}
      </div>
    </div>
  );
}
export default LeftPanel;
