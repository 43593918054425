import React from "react";
import { useTranslation } from "react-i18next";
import { Layout as DSLayout, Typography } from "@ogury/design-system";
import OguryLogo from "assets/img/Logo.svg";
import LinkImg from "assets/img/LinkImg.svg";
import styles from "./Layout.module.scss";

function Layout({ children }: { children: React.ReactNode }) {
  return <DSLayout className={styles.layout}>{children}</DSLayout>;
}

function Sider({ children }: { children: React.ReactNode }) {
  const [t] = useTranslation();

  return (
    <DSLayout.Sider width={250} className={styles.sider}>
      <div className={styles.logo}>
        <img id="logo" src={OguryLogo} alt="Ogury logo" />
      </div>
      {children}
      <div className={styles.siderFooter}>
        <Typography.TextLink2 href="https://www.ogury.com">
          {t("DemoShowcase.learMoreAboutOgury", "Learn more about Ogury Ads")}&nbsp;&nbsp;
          <img id="linkImg" src={LinkImg} alt="linkImg" />
        </Typography.TextLink2>
      </div>
    </DSLayout.Sider>
  );
}

function Content({ children }: { children: React.ReactNode }) {
  return <DSLayout.Content className={styles.content}>{children}</DSLayout.Content>;
}

Layout.Sider = Sider;
Layout.Content = Content;

export default Layout;
